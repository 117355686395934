.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: none !important;
}

.SliderDiv {
  background-color: #000;
  width: 100%;
}
.SliderDiv img {
  object-fit: cover !important;
}
.withoutPointer {
  cursor: default !important;
}
.SliderSection .image-gallery-svg {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}
.SliderSection .image-gallery-bullet {
  background-color: rgba(0, 0, 0, 0.5);
}
.image-gallery-custom-action {
  position: absolute;
  top: 10px;
  right: 12px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px;
  border-radius: 4px;
  text-decoration: none;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.image-gallery-custom-action:focus {
  outline: 2px solid #007bff;
  outline-offset: 2px;
}
