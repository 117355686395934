*,
*::before,
*::after {
  box-sizing: border-box;
}

.overlay {
  position: absolute;
  margin: 5px;
}

.overlay-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.overlay-top-left {
  top: 0;
  left: 0;
}
.overlay-top-right {
  top: 0;
  right: 0;
}
.overlay-top-center {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.overlay-bottom-left {
  bottom: 0;
  left: 0;
}
.overlay-bottom-right {
  bottom: 0;
  right: 0;
}
.overlay-bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
